import React, { FC } from 'react';
import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';
import posterImage from '../../public/HomePageAnimationPoster.jpg';

export const Container = styled.div`
    background-color: #cbbaaa;
    background-image: url(${posterImage.src});
    background-position: 85% 50%;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
`;

export const Wrapper = styled.div`
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-shadow: 0px 0px 6px rgb(0 0 0 / 50%);
`;

export const Title = styled.div`
    text-align: center;
    margin-bottom: 26px;
    color: ${Color.Surface};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 0.063rem;

    @media (min-width: ${Breakpoint.Tablet}) {
        font-size: 1.625rem;
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        font-size: 1.75rem;
    }

    @media (min-width: ${Breakpoint.LargeDesktop}) {
        font-size: 2rem;
    }
    @media (min-width: ${Breakpoint.ExtraLargeDesktop}) {
        font-size: 3rem;
    }
`;

const SquareImageWrapper = styled.div`
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
`;

const SquareImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const Square: FC<{ children: React.ReactNode }> = ({ children }) => (
    <SquareImageWrapper>
        <SquareImage>{children}</SquareImage>
    </SquareImageWrapper>
);

export const InstagramSection = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    position: relative;

    &:after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 200px;
        bottom: 0;

        background: linear-gradient(180deg, rgba(245, 243, 240, 0) 0%, #f5f3f0 100%);
    }

    @media (min-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr 1fr;
        &:after {
            height: 16%;
        }
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        grid-template-columns: 1fr 1fr 1fr;
        &:after {
            height: 25%;
        }
    }
`;

export const HomeContainer = styled.div`
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 24px;
`;

export const HomeScrollButton = styled.div`
    margin: -96px auto 40px;
    height: 56px;
    width: 56px;
    background: rgba(255, 255, 255, 0.59);
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const Section1Container = styled.div`
    display: grid;
    gap: 40px;
    margin: 120px 0 80px 0;
    align-items: center;

    @media (min-width: ${Breakpoint.Desktop}) {
        gap: 80px;
        grid-template-columns: 1fr 1fr;
        margin: 120px 0 155px 0;
    }
`;

export const Section1ImageContainer = styled.div`
    box-shadow: -56px -32px 0px -8px #f5f3f3;

    @media (min-width: ${Breakpoint.Desktop}) {
        box-shadow: 32px 77px 0px 12px #f5f3f0, -540px 77px 0px 12px #f5f3f0;
    }
`;

export const Section2Container = styled.div`
    gap: 0;
    margin: 66px 0px 169px;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    &:after {
        border-top: 1px solid #afafaf;
        content: '';
        position: absolute;
        width: 100%;
        bottom: -80px;
        display: block;
    }

    @media (min-width: ${Breakpoint.Desktop}) {
        display: grid;
        gap: 80px;
        grid-template-columns: 1fr 1fr;

        &:after {
            display: none;
        }
    }
`;

export const Section2TextContainer = styled.div`
    padding-top: 145px;

    @media (min-width: ${Breakpoint.Desktop}) {
        margin-right: -80px;
        padding: 260px 80px;
        background: rgb(245, 243, 240);
    }
`;

export const Section2ImageContainer = styled.div`
    box-shadow: 80px 40px 0px 10px #f5f3f0;
    position: relative;

    @media (min-width: ${Breakpoint.Desktop}) {
        box-shadow: none;
    }
`;

export const Section2ImageAdditionalContainer = styled.div`
    width: 188px;
    position: absolute;
    bottom: -100px;
    right: 0;

    @media (min-width: ${Breakpoint.Desktop}) {
        width: 55%;
        max-width: 288px;
        right: calc(100% - 76px);
        bottom: -89px;
    }
`;

export const ButtonScrollTopContainer = styled.div`
    margin: 0 -16px;

    @media (min-width: ${Breakpoint.LargeDesktop}) {
        margin: 0 -36px;
    }
`;
