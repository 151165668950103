import React, { FC, useEffect, useState } from 'react';
import { Button } from '@formify/frontend-components';
import {
    Container,
    Wrapper,
    Title,
    Square,
    InstagramSection,
    HomeContainer,
    HomeScrollButton,
    Section1Container,
    Section1ImageContainer,
    Section2Container,
    Section2ImageAdditionalContainer,
    Section2TextContainer,
    Section2ImageContainer,
    ButtonScrollTopContainer,
} from './Home.styled';
import posterImage from '../../public/HomePageAnimationPoster.jpg';
import { StaticPageImageOverlay } from '../StaticPageContent/StaticPageContent.styled';
import { Crisp } from '../Crisp/Crisp';
import { Footer } from '../Footer/Footer';

const dangerouslySetInnerHTMLValue = {
    __html: `
    <video poster="${posterImage.src}"
        loop
        muted
        autoplay
        playsinline
        style="width: 100%; height: 100vh; object-fit: cover; object-position: 85% 50%;"
    >
        <source src="/HomePageAnimation.webm" type="video/webp" />
        <source src="/HomePageAnimation.mp4" type="video/mp4" />
    </video>
`,
};

import section1Image from './resources/section1.jpg';
import section2Image from './resources/section2.jpg';
import section21Image from './resources/section2.1.jpg';
import instaImage1 from './resources/insta1.jpg';
import instaImage2 from './resources/insta2.jpg';
import instaImage3 from './resources/insta3.jpg';
import instaImage4 from './resources/insta4.jpg';
import instaImage5 from './resources/insta5.jpg';
import instaImage6 from './resources/insta6.jpg';
import Image from 'next/image';
import { DisplayS } from '@formify/frontend-components';
import { TextM } from '@formify/frontend-components';
import { ChevronUpIcon } from '../icons/ChevronUp/ChevronUp';
import Link from 'next/link';

export const Home: FC = () => {
    const [scrollToTopVisible, setScrollToTopVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollToTopVisible(window.scrollY > 50);
        });
    });

    return (
        <Container>
            <Crisp showOnMobile={true} />
            <StaticPageImageOverlay bottom="50%" />
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTMLValue} />
            <Wrapper>
                <Title>Storage to make a statement</Title>
                <Link href="/wardrobe" passHref prefetch={true}>
                    <Button variant="subtle">Design yours</Button>
                </Link>
            </Wrapper>

            <div>
                <HomeScrollButton
                    onClick={() => {
                        window.scrollTo({
                            top: window.innerHeight,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <svg width="16" height="35" viewBox="0 0 16 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.699951" width="15" height="24.6" rx="7.5" stroke="currentColor" />
                        <path d="M8 6.59998V9.79998" stroke="black" strokeLinecap="round" />
                        <path d="M3.2 30.6L8 33.8L12.8 30.6" stroke="black" strokeLinecap="round" />
                    </svg>
                </HomeScrollButton>
            </div>

            <HomeContainer>
                <Section1Container>
                    <Section1ImageContainer>
                        <Square>
                            <Image src={section1Image} alt="" layout="fill" />
                        </Square>
                    </Section1ImageContainer>
                    <div>
                        <DisplayS className="mb-32">Taking your home to the next level</DisplayS>

                        <TextM className="mb-16">
                            Starting and ending the day in front of a closet this beautifully made, adds everyday value
                            to the style and comfort of your home. Besides, since our base materials are recycled, you
                            will have some peace of mind too.
                        </TextM>
                        <TextM>Order now and the measurement and installation service is on us!</TextM>
                    </div>
                </Section1Container>

                <Section2Container>
                    <Section2TextContainer>
                        <DisplayS className="mb-32">It’s all in the details, and the senses</DisplayS>

                        <TextM>
                            The soft touch and wonderfull structures of our panels. The solid and heavy quality handles.
                            The warm and soft dimming lights. No detail has slipped our minds, hands and eyes. Owning a
                            Formify closet means you care about perfection.
                        </TextM>
                    </Section2TextContainer>
                    <Section2ImageContainer className="relative">
                        <Image src={section2Image} alt="" />

                        <Section2ImageAdditionalContainer>
                            <Square>
                                <Image src={section21Image} alt="" layout="fill" />
                            </Square>
                        </Section2ImageAdditionalContainer>
                    </Section2ImageContainer>
                </Section2Container>

                <DisplayS className="text-center mt-96 mb-32">Showroom</DisplayS>

                <InstagramSection>
                    <Square>
                        <Image src={instaImage1} alt="" layout="fill" />
                    </Square>
                    <Square>
                        <Image src={instaImage2} alt="" layout="fill" />
                    </Square>
                    <Square>
                        <Image src={instaImage3} alt="" layout="fill" />
                    </Square>
                    <Square>
                        <Image src={instaImage4} alt="" layout="fill" />
                    </Square>
                    <Square>
                        <Image src={instaImage5} alt="" layout="fill" />
                    </Square>
                    <Square>
                        <Image src={instaImage6} alt="" layout="fill" />
                    </Square>
                </InstagramSection>
                <a href="https://www.instagram.com/formify.closets" target="_blank" rel="noopener noreferrer">
                    <Button className="mt-32 mb-160 mx-auto" variant="subtle">
                        Visit our instagram
                    </Button>
                </a>
            </HomeContainer>

            <Footer />
            {scrollToTopVisible && <ScrollToTopButton />}
        </Container>
    );
};

const STYLE = { position: 'fixed', left: '0px', width: '100%', bottom: 0 } as const;

const ScrollToTopButton = () => (
    <div style={STYLE}>
        <HomeContainer>
            <ButtonScrollTopContainer className="py-8 flex justify-end">
                <Button
                    variant="subtle"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                    icon={<ChevronUpIcon />}
                ></Button>
            </ButtonScrollTopContainer>
        </HomeContainer>
    </div>
);
