import type { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Header } from '../components/Header/Header';
import { Home } from '../components/Home/Home';
import { AllDecorators } from '../decorators/forStaticPages/AllDecorators';
import { useChannel } from '../components/Channel/ChannelProvider';

const HomePage: NextPage = () => {
    const { name } = useChannel();
    const [transparent, setTransparent] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setTransparent(window.scrollY === 0);
        });
    });

    return (
        <>
            <Head>
                <title property="og:title">{name}</title>
                <meta name="description" property="og:description" content="Storage to make a statement" />
                <link rel="preload" as="video" href="/HomePageAnimation.webm" type="video/webm" />
                <link rel="preload" as="image" href="/images/shimmer.svg" type="image/svg+xml" />
                <meta name="facebook-domain-verification" content="e7vnkif1g34mqlpe84zv8bfrhhj181" />
            </Head>
            <Header isVisible transparent={transparent} />
            <AllDecorators>{() => <Home />}</AllDecorators>
        </>
    );
};

export const getStaticProps = async () => ({
    props: {
        initialReduxActions: [],
        animation: null,
    },
});

export default HomePage;
